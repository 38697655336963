import React from "react"
import PropTypes from "prop-types"

import { Link, graphql } from "gatsby"

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark
  const tagHeader = `"#${tag}" は ${totalCount}件の記事があります。`

  return (
    <div className="global-wrapper">
      <h1>{tagHeader}</h1>
      <ul style={{ listStyle: `none` }}>
        {edges.map(({ node }) => {
          const { slug } = node.fields
          const { title, date } = node.frontmatter

          return (
            <li key={slug}>
              <Link to={slug} style={{ textDecoration: `none` }}>{title}</Link>
              <p>{date}</p>
            </li>
          )
        })}
      </ul>
      {/*
              This links to a page that does not yet exist.
              You'll come back to it!
            */}
      <Link to="/tags" style={{ textDecoration: `none`, paddingRight: 3 }}>
        #タグ
      </Link>
      <Link to="/" style={{ textDecoration: `none` }}>
        トップ
      </Link>
    </div>
  )
}

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
              date: PropTypes.string.isRequired
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "YYYY/MM/DD")
          }
        }
      }
    }
  }
`
